body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@font-face {
  font-family: "Norse-Kawl";
  src: local('Norse-Kawl'), url('../fonts/Norse-KaWl.otf') format('opentype');
}
@font-face {
  font-family: "NorseBold-2Kge";
  src: local('NorseBold-2Kge'), url('../fonts/NorseBold-2Kge.otf') format('opentype');
}
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/raleway/v27/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaooCP.ttf) format('truetype');
}
.landing {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 0;
  background: #0031c4;
}
.about {
  height: 500px;
  display: flex;
  justify-content: center;
  background: #ffffff;
}
.about .content {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}
.quarter-container {
  display: flex;
  height: 800px;
  background: #ffffff;
}
.quarter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: flex-start;
  width: 33.333%;
  margin: 0 20px;
  background-color: #0031c4;
  color: #ffd966;
}
.quarter h2 {
  margin: auto;
  margin-bottom: 5%;
}
.stroke {
  text-decoration: none;
  font-weight: bold;
  font-size: 20px;
  color: hsl(45, 100%, 70%);
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  background-color: white;
}
@media (max-width: 640px) {
  .stroke {
    font-size: 14px;
    text-align: center;
  }
}
.fade-in-text {
  display: inline-block;
  font-family: NorseBold-2Kge;
  font-size: 32px;
  color: #ffd966;
  animation: fadeIn 6s ease;
}
@media (min-width: 641px) and (max-width: 1007px) {
  .fade-in-text {
    font-size: 28px;
  }
}
@media (min-width: 391px) and (max-width: 640px) {
  .fade-in-text {
    font-size: 24px;
  }
}
@media (max-width: 390px) {
  .fade-in-text {
    font-size: 20px;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fade-in-text2 {
  display: inline-block;
  font-size: 60px;
  color: #ffd966;
  animation: fadeIn2 5s ease;
  font-family: Norse-KaWl;
}
@media (min-width: 641px) and (max-width: 1007px) {
  .fade-in-text2 {
    font-size: 50px;
  }
}
@media (min-width: 391px) and (max-width: 640px) {
  .fade-in-text2 {
    font-size: 45px;
  }
}
@media (max-width: 390px) {
  .fade-in-text2 {
    font-size: 40px;
  }
}
@keyframes fadeIn2 {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.tech-mesh {
  background-image: url('../images/tech\ background.jpeg');
  background-position-x: right;
  background-size: cover;
  filter: grayscale(75%);
}
.mobile-mesh {
  background-image: url(../images/mobile-devices.jpeg);
  filter: grayscale(75%);
}
.big-data {
  background-image: url(../images/big-data.jpeg);
  background-position: center;
  background-size: cover;
  filter: grayscale(75%);
}
.money-mesh {
  background-image: url(../images/money-mesh.jpeg);
  background-size: cover;
  background-position: center;
  filter: grayscale(75%);
}
.overlay {
  width: 100%;
  height: auto;
  background-color: rgba(10, 10, 10, 0.5);
  height: 800px;
  color: white;
}
.overlay h2 {
  margin-top: 60%;
  font-size: 28px;
}
.overlay p {
  font-size: 18px;
  font-weight: bold;
}
.link {
  border: solid #ffd966 2px;
  background-color: #0031c4;
  width: 110px;
  padding: 10px 10px;
  border-radius: 5px;
  margin: 5px 5px;
}
.link:hover {
  background-color: #ffd966;
  color: #0031c4;
  transition: all 500ms ease;
}
@media (max-width: 640px) {
  .link {
    width: 55px;
    padding-right: 15px;
  }
}
.link_container {
  width: 20%;
  display: flex;
  justify-content: space-evenly;
}
@media (max-width: 640px) {
  .link_container {
    margin-left: 50%;
  }
}
.header2 {
  color: black;
  font-family: 'Raleway', sans-serif;
  margin: auto;
  margin-bottom: 5%;
  font-size: 48px;
}
@media (min-width: 641px) and (max-width: 1007px) {
  .header2 {
    font-size: 40px;
  }
}
@media (min-width: 391px) and (max-width: 640px) {
  .header2 {
    font-size: 32px;
  }
}
@media (max-width: 390px) {
  .header2 {
    font-size: 28px;
  }
}
.quarter h3 {
  margin: auto;
  font-family: 'Raleway', sans-serif;
  font-weight: 800;
  margin-bottom: 0;
  margin-top: 10%;
  font-size: 24px;
}
.quarter p {
  margin: auto;
  margin-top: 5%;
  margin-left: 10%;
  margin-right: 10%;
  font-size: 20px;
  text-align: left;
  font-weight: 300;
}
.grid {
  background-color: #0031c4;
  color: #ffd966;
}
.grid h3 {
  margin-top: 18%;
}
.cta-button {
  width: 300px;
  margin: auto;
  margin-top: 2%;
  height: 50px;
  background-color: #0031c4;
  color: #ffd966;
  font-family: 'Raleway', sans-serif;
  font-size: 18px;
}
.cta-button:hover {
  background-color: #ffd966;
  color: #0031c4;
  transition: all 500ms ease;
}
.about .content p {
  width: 50%;
  margin: auto;
  margin-top: 0;
  font-size: 18px;
  text-align: left;
}
@media (max-width: 1007px) {
  .about .content p {
    width: 90%;
  }
}
.marketing {
  display: flex;
  margin: auto;
  min-height: 300px;
  width: 75%;
}
@media (max-width: 769px) {
  .marketing {
    width: 100%;
    justify-content: center;
    flex-direction: column;
  }
}
@media (min-width: 641px) and (max-width: 769px) {
  .marketing .quarter {
    width: 50%;
    margin: auto;
    margin-top: 2%;
    min-height: 300px;
  }
}
@media (min-width: 391px) and (max-width: 640px) {
  .marketing .quarter {
    width: 50%;
    margin: auto;
    margin-top: 2%;
    min-height: 300px;
  }
}
@media (max-width: 390px) {
  .marketing .quarter {
    width: 80%;
    margin: auto;
    margin-top: 5%;
    min-height: 300px;
  }
}
.gallery {
  height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  background: #fff;
}
.stripe {
  height: 500px;
  display: flex;
  justify-content: center;
  background: #fff;
  margin: auto;
  margin-bottom: 0;
  width: 75%;
}
@media (max-width: 1007px) {
  .stripe {
    flex-direction: column;
  }
}
.stripelogo {
  margin: auto;
  width: 40%;
}
@media (max-width: 1007px) {
  .stripelogo {
    width: 75%;
  }
}
.stripe .content {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin-top: 0;
  margin-left: 0;
}
@media (max-width: 1007px) {
  .stripe .content {
    width: 100%;
  }
}
.stripe .content h2 {
  margin: auto;
  margin-bottom: 5%;
  font-family: 'Raleway', sans-serif;
  font-weight: 800;
  margin-left: 22%;
  font-size: 32px;
}
@media (max-width: 1007px) {
  .stripe .content h2 {
    margin: auto;
    margin-top: 5%;
  }
}
.stripe .content p {
  width: 75%;
  margin: auto;
  margin-top: 0;
  margin-left: 5%;
  font-family: 'Raleway', sans-serif;
  text-align: left;
  font-size: 18px;
}
@media (max-width: 1007px) {
  .stripe .content p {
    margin: auto;
    margin-top: 5%;
  }
}
.business_solutions {
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  background: #fff;
  margin-top: 5%;
}
@media (max-width: 1007px) {
  .business_solutions {
    height: auto;
    margin-top: 10%;
  }
}
.business_solutions h2 {
  margin: auto;
  margin-bottom: 2%;
  font-family: 'Raleway', sans-serif;
  font-weight: 800;
  font-size: 36px;
}
.business_solutions .content {
  display: flex;
  margin: auto;
  min-height: 300px;
  width: 75%;
}
@media (max-width: 1007px) {
  .business_solutions .content {
    flex-direction: column;
    justify-content: center;
  }
}
.business_solutions .content .quarter {
  min-height: 100px;
  margin-top: 2%;
}
@media (min-width: 641px) and (max-width: 1007px) {
  .business_solutions .content .quarter {
    margin: auto;
    min-height: 250px;
    margin-top: 2%;
    width: 90%;
  }
}
@media (max-width: 640px) {
  .business_solutions .content .quarter {
    margin: auto;
    min-height: 250px;
    margin-top: 2%;
    width: 80%;
  }
}
.spacer-top {
  margin-top: 2%;
}
.cta {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 5%;
}
.cta h2 {
  margin: auto;
  margin-bottom: 0;
  font-family: 'Raleway', sans-serif;
}
#triknot {
  height: 500px;
  width: 500px;
}
@media (min-width: 641px) and (max-width: 1007px) {
  #triknot {
    height: 350px;
  }
}
@media (min-width: 391px) and (max-width: 640px) {
  #triknot {
    height: 250px;
  }
}
@media (max-width: 390px) {
  #triknot {
    height: 150px;
  }
}
.tag {
  text-align: center;
}
.slick-slide img {
  margin: auto;
  width: 50%;
  height: 50%;
}
.label {
  text-align: left;
  margin: 10px 0px;
}
.field {
  margin: 3px 0px;
  border-radius: 0;
  height: 35px;
}
.formButton {
  height: 50px;
  background-color: #0031c4;
  color: #ffd966;
  font-family: 'Raleway', sans-serif;
  font-size: 18px;
  font-weight: bolder;
}
.formButton:hover {
  background-color: #ffd966;
  color: #0031c4;
  transition: all 500ms ease;
}
.contact_main {
  margin: auto;
  display: flex;
  flex-direction: column;
  margin-top: 75px;
  font-family: 'Raleway', sans-serif;
}
.form_container {
  display: flex;
  flex-direction: column;
  margin-bottom: 5%;
}
.form {
  display: flex;
  flex-direction: column;
  border: solid grey 1px;
  width: 920px;
  margin: auto;
  background-color: #efefef;
  justify-content: center;
}
@media (min-width: 391px) and (max-width: 769px) {
  .form {
    width: 640px;
  }
}
@media (max-width: 390px) {
  .form {
    width: 350px;
  }
}
.form_wrapper {
  width: 85%;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 40px;
  height: auto;
}
.customer_name {
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
#firstName,
#lastName {
  width: 45%;
  height: 35px;
}
.checkboxes {
  display: flex;
}
.checkboxes_container {
  display: flex;
  flex-direction: column;
  width: 40%;
}
@media (min-width: 391px) and (max-width: 769px) {
  .checkboxes_container {
    width: 80%;
  }
}
@media (max-width: 390px) {
  .checkboxes_container {
    width: 80%;
  }
}
@media (max-width: 390px) {
  .checkboxes_container .label {
    font-size: 12px;
  }
}
.message_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.messagefield {
  height: 120px;
  margin: 20px 0px;
  font-family: Arial;
}
.pricing h2 {
  font-size: 36px;
}
.pricing p {
  font-size: 24px;
}
.pricing li {
  font-size: 22px;
  list-style: none;
}
.services {
  width: 1600px;
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.service {
  width: 750px;
  padding: 20px 10px;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  border: solid blue 3px;
}
@media (min-width: 391px) and (max-width: 800px) {
  .service {
    width: 50%;
  }
}
@media (max-width: 390px) {
  .service {
    width: 75%;
  }
}
.service p {
  text-align: left;
}
